import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

// IMAGENES MATERIAS
const artesImg = "/images/perfil-estudiante/libros/explorar/materias/artes.png";
const cienciasImg = "/images/perfil-estudiante/libros/explorar/materias/ciencia.png";
const historiaImg = "/images/perfil-estudiante/libros/explorar/materias/historia.png";
const narrativoImg = "/images/perfil-estudiante/libros/explorar/materias/narrativo.png";
const letrasImg = "/images/perfil-estudiante/libros/explorar/materias/letras.png";
const cancionImg = "/images/perfil-estudiante/libros/explorar/materias/cancion.png";
// IMAGENES GENEROS
const interactivoImg = "/images/perfil-estudiante/libros/explorar/generos/interactivo.png";
const comicImg = "/images/perfil-estudiante/libros/explorar/generos/comic.png";

const opcionesEstaticas = [
	{
		tipo: "genero",
		imagen: interactivoImg,
		textoPorDefecto: "Interactivo",
		propiedadAPI: "Interactivo",
		id: "interactivo",
		filtroActivado: false,
	},
	{
		tipo: "genero",
		imagen: comicImg,
		textoPorDefecto: "Cómic",
		propiedadAPI: "Cómic",
		id: "comic",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: narrativoImg,
		textoPorDefecto: "Narrativa",
		propiedadAPI: "Narrativa",
		id: "narrativa",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: letrasImg,
		textoPorDefecto: "Letras y números",
		propiedadAPI: "Letras y números",
		id: "letras_numeros",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: cancionImg,
		textoPorDefecto: "Canción y poesía",
		propiedadAPI: "Canción y poesía",
		id: "cancion",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: historiaImg,
		textoPorDefecto: "Historia y ensayo",
		propiedadAPI: "Historia y ensayo",
		id: "historia",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: cienciasImg,
		textoPorDefecto: "Ciencias",
		id: "ciencias",
		propiedadAPI: "Ciencias",
		filtroActivado: false,
	},
	{
		tipo: "materia",
		imagen: artesImg,
		textoPorDefecto: "Artes y manualidades",
		propiedadAPI: "Artes y manualidades",
		id: "artes",
		filtroActivado: false,
	},
];

/**
 * Componente que recibe una serie de propiedades que son necesarios para mostrar la sección de explorar libros. Este componente cuenta con dos funciones ExplorarLibros y OpcionExplorar.
 * @returns ExplorarLibros
 */
export const Filters = ({ alCambiarModoFiltro, alLimpiarFiltros, loadingData }) => {
	const [modoFiltro, setModoFiltro] = useState({
		activado: undefined,
		filtros: {
			materias: [],
			generos: [],
			favorito: false,
			sch: "",
		},
		cantidadFiltrosActivos: 0,
	});
	const [totalAppliedFilters, setTotalAppliedFilters] = useState(0);
	const { isReady, textosSubSecciones } = useObtenerSubsecciones("perfil_estudiante");
	const traducciones = textosSubSecciones;
	const buscadorRef = useRef(undefined);
	const [opcionesFiltros, setOpcionesFiltros] = useState(opcionesEstaticas);
	const [inputValue, setInputValue] = useState("");
	const emptyField = !inputValue || inputValue?.toLowerCase()?.length <= 0;
	// const interactiveFilterActivated = modoFiltro?.filtros?.generos?.find(
	// 	(option) => option.id === "interactivo"
	// );

	const limpiarFiltros = () => {
		setInputValue("");
		const filters = {
			activado: false,
			filtros: {
				favorito: false,
				generos: [],
				materias: [],
				sch: "",
			},
			cantidadFiltrosActivos: 0,
		};
		FiltroMaterias.limpiar();
		FiltroGeneros.limpiar();
		setTotalAppliedFilters(0);
		applyFilters({ filters });
		setModoFiltro(filters);
		if (buscadorRef.current) {
			buscadorRef.current.value = "";
		}
		alLimpiarFiltros && alLimpiarFiltros();
	};

	const FiltroMaterias = {
		agregar: (materia) => {
			const aux = modoFiltro.filtros.materias.filter((opcion) => materia?.id !== opcion?.id);
			aux.push(materia);
			setModoFiltro({
				...modoFiltro,
				activado: true,
				filtros: {
					...modoFiltro.filtros,
					materias: aux,
				},
			});
		},
		remover: (materia) => {
			const aux = modoFiltro.filtros.materias.filter((opcion) => materia?.id !== opcion?.id);
			setModoFiltro({
				...modoFiltro,
				filtros: {
					...modoFiltro.filtros,
					materias: aux,
				},
			});
		},
		limpiar: () => {
			setModoFiltro({
				...modoFiltro,
				filtros: {
					...modoFiltro.filtros,
					materias: [],
				},
			});
		},
	};

	const FiltroGeneros = {
		agregar: (genero) => {
			const aux = modoFiltro.filtros.generos.filter((opcion) => genero?.id !== opcion?.id);
			aux.push(genero);
			setModoFiltro({
				...modoFiltro,
				activado: true,
				filtros: {
					...modoFiltro.filtros,
					generos: aux,
				},
			});
		},
		remover: (genero) => {
			const aux = modoFiltro.filtros.generos.filter((opcion) => genero?.id !== opcion?.id);
			setModoFiltro({
				...modoFiltro,
				filtros: {
					...modoFiltro.filtros,
					generos: aux,
				},
			});
		},
		limpiar: () => {
			setModoFiltro({
				...modoFiltro,
				filtros: {
					...modoFiltro.filtros,
					generos: [],
				},
			});
		},
	};

	const FiltroFavoritos = {
		setActivado: (activado) => {
			setModoFiltro({
				...modoFiltro,
				activado: true,
				filtros: {
					...modoFiltro.filtros,
					favorito: activado,
				},
			});
		},
	};

	const handleInputSearch = () => {
		if (modoFiltro?.filtros?.sch === inputValue) return;
		if (emptyField) return;
		const filters = {
			...modoFiltro,
			activado: true,
			filtros: {
				...modoFiltro.filtros,
				sch: inputValue,
			},
			cantidadFiltrosActivos: modoFiltro.cantidadFiltrosActivos + 1,
		};
		applyFilters({ filters });
		setModoFiltro(filters);
	};

	// const handleInteractiveFilter = () => {
	// 	const hasFilter = interactiveFilterActivated;
	// 	const filter = opcionesEstaticas.find((opcion) => opcion.id === "interactivo");
	// 	if (hasFilter) {
	// 		FiltroGeneros.remover(filter);
	// 	} else {
	// 		FiltroGeneros.agregar(filter);
	// 	}
	// };

	const applyFilters = ({ filters }) => {
		setTotalAppliedFilters(filters.cantidadFiltrosActivos);
		if (alCambiarModoFiltro) {
			alCambiarModoFiltro(filters);
		}
	};

	useEffect(() => {
		const actualizarEstadoOpciones = () => {
			const materiasIds = modoFiltro.filtros.materias.map((opcion) => opcion.id);
			const generosIds = modoFiltro.filtros.generos.map((opcion) => opcion.id);
			setOpcionesFiltros(
				[...opcionesFiltros].map((opcion) => {
					let activado = false;
					if (materiasIds.includes(opcion?.id) || generosIds.includes(opcion?.id)) {	
						activado = true;
					}
					return { ...opcion, filtroActivado: activado };
				})
			);
			
		};

		const contarFiltros = () => {
			const cantidadFiltrosActivos = Object.values(modoFiltro.filtros).reduce((acc, curr) => {
				if (Array.isArray(curr) && curr.length > 0) {
					curr.forEach((item) => {
						acc += 1;
					});
				} else if (typeof curr === "string" && curr.trim().length > 0) {
					acc += 1;
				} else if (typeof curr === "boolean" && curr === true) {
					acc += 1;
				}
				return acc;
			}, 0);

			setModoFiltro({ ...modoFiltro, cantidadFiltrosActivos: cantidadFiltrosActivos });
		};

		contarFiltros();
		actualizarEstadoOpciones();
		if (
			modoFiltro.filtros.materias.length <= 0 &&
			modoFiltro.filtros.generos.length <= 0 &&
			modoFiltro.filtros.favorito === false &&
			modoFiltro.filtros.sch.trim().length <= 0
		) {
			setModoFiltro({ ...modoFiltro, activado: false, cantidadFiltrosActivos: 0 });
		}
	}, [modoFiltro?.filtros]);

	return (
		<>
			{isReady && (
				<section className={`relative primary-var2--bg borde-buscar rounded-br-3xl`}>
					<div className="contenido pt-2">
						<div className="relative grid grid-cols-2 sm:grid-cols-3  xl:grid-cols-4 grid-rows-none">
							<div className=" min-w-full space-x-2 center col-span-full md:col-span-3 px-2">
								<form
									className="relative w-full"
									onSubmit={(e) => {
										e.preventDefault();
										handleInputSearch();
									}}
								>
									<input
										ref={buscadorRef}
										type="text"
										className="w-full focusable-red"
										value={inputValue}
										defaultValue={inputValue}
										placeholder={
											traducciones.libros.buscador.input.placeholder ||
											"Busca aquí por título, autor, palabra clave..."
										}
										onChange={(e) => {
											setInputValue(e?.target?.value);
											// setModoFiltro({...modoFiltro, activado:true})
										}}
									/>
									<button
										type="submit"
										disabled={loadingData}
										aria-label="Buscar"
										className={`focusable-red absolute ${
											emptyField
												? "primary-var1--bg onprimary-var1--color"
												: "secondary-var1--bg onsecondary-var1--color"
										}   right-0 top-0 w-10 h-full`}
									>
										<span className="icon-lupa"></span>
									</button>
								</form>
							</div>
							<div className=" my-4">
								<div className="pl-1 pr-2">
									<button
										className={`
                                    focusable-red
                                    flex flex-row
                                    items-center
                                    border-solid border-2 onprimary-var1--bordered rounded-xl 
                                    boton-desactivado-libros cursor-pointer 
                                    px-2 py-1 
                                    overflow-hidden
                                    boton_hover_desactivado_libros
                                    transicion-150
                                    font-bold
                                    place-self-center
                                    h-9
                                    w-full
                                    surface-var2--color
                            ${
								modoFiltro.filtros?.favorito
									? "boton-activado-libros onprimary-inv-var2--color"
									: "surface-var2--color"
							}
                            `}
										onClick={() => {
											FiltroFavoritos.setActivado(
												!modoFiltro.filtros.favorito
											);
										}}
									>
										<Icon icon={"ic:outline-favorite"} width={25} />
										<p className="p-2 quicksand-700 text-center  overflow-hidden w-50 whitespace-nowrap overflow-ellipsis">
											{traducciones.botones.buscador.filtro_favorito ||
												"Favoritos"}
										</p>
									</button>
								</div>
								{/* <div className="pr-2">
									<button
										className={`
                                    focusable-red
                                    flex flex-row
                                    items-center
                                    border-solid border-2 onprimary-var1--bordered rounded-xl 
                                    boton-desactivado-libros cursor-pointer 
                                    px-2 py-1 
                                    overflow-hidden
                                    boton_hover_desactivado_libros
                                    transicion-150
                                    font-bold
                                    place-self-center
                                    h-9
                                    w-full
                                    surface-var2--color
                                
                            ${
								interactiveFilterActivated
									? "boton-activado-libros onprimary-inv-var2--color"
									: "surface-var2--color"
							}
                            `}
										onClick={() => {
											handleInteractiveFilter();
										}}
									>
										<div
											className={`${
												interactiveFilterActivated ? "" : "filter grayscale"
											}`}
											style={{
												width: 22,
												height: 22,
												minWidth: 22,
												minHeight: 22,
											}}
										>
											{
												<img
													src={
														opcionesEstaticas.find(
															(opcion) => opcion.id === "interactivo"
														).imagen
													}
													alt={"interactivo"}
													className={"min-w-full max-h-full min-h-full"}
												/>
											}
										</div>
										<p className="p-2 quicksand-700 text-center  overflow-hidden w-50 whitespace-nowrap overflow-ellipsis">
											{traducciones?.libros?.buscador?.genero?.interactivo ||
												"Interactivos"}
										</p>
									</button>
								</div> */}
							</div>
						</div>
						<section className="relative z-50">
							{/* SECCION STICKY DE MAS OPCIONES */}
							<section
								className={`w-full flex flex-col center rounded-b-2xl transicion-150`}
							>
								{/* OPCIONES */}
								<section className="w-full px-2 py-0 gap-2 grid grid-cols-2 sm:grid-cols-3  xl:grid-cols-4 grid-rows-none items-start place-items-center my-3">
									{opcionesFiltros.map((opcionExplorar, i) => {
										const opcionTransformada = {
											...opcionExplorar,
											nombre:
												traducciones.libros.buscador[opcionExplorar.tipo][
													opcionExplorar.id
												] || opcionExplorar.textoPorDefecto,
										};
										// if (opcionExplorar.tipo === "genero") return null;
										return (
											<OpcionExplorar
												key={opcionExplorar.id}
												opcion={opcionTransformada}
												ilustracion={
													<img
														src={opcionExplorar.imagen}
														alt={opcionExplorar.nombre}
														className={
															"min-w-full max-h-full min-h-full"
														}
													/>
												}
												alSeleccionar={(opcion) => {
													if(opcion.tipo === "genero"){
														FiltroGeneros.agregar(opcion);
													}
													if(opcion.tipo === "materia"){
														FiltroMaterias.agregar(opcion);
													}
												}}
												alDeseleccionar={(opcion) => {
													if(opcion.tipo === "genero"){
														FiltroGeneros.remover(opcion);
													}
													if(opcion.tipo === "materia"){
														FiltroMaterias.remover(opcion);
													}
												}}
												marcarSeleccionado={opcionExplorar?.filtroActivado}
											/>
										);
									})}
								</section>
								<div className="w-full flex justify-center">
									<div className="flex  my-4">
										<div className="flex flex-col sm:flex-row  items-center">
											{Boolean(
												modoFiltro?.activado || totalAppliedFilters > 0
											) && (
												<button
													className="mr-2 focusable-primary block boton-pequenio w-40 boton-amarillo mb-3 sm:mx-3 hover:boton_hover_filtros "
													disabled={loadingData}
													onClick={() =>
														applyFilters({ filters: modoFiltro })
													}
												>{`${
													traducciones.botones.buscador.aplicar_filtros ||
													"Aplicar filtros"
												} (${modoFiltro.cantidadFiltrosActivos})`}</button>
											)}
											<div className="flex flex-row  items-center">
												{totalAppliedFilters > 0 && (
													<button
														className="focusable-primary block boton-pequenio w-40 boton-rojo mb-2 sm:mx-3 hover:boton_hover_filtros "
														disabled={loadingData}
														onClick={() => limpiarFiltros()}
													>{`${
														traducciones.botones.buscador
															.limpiar_filtros || "Limpiar filtros"
													}`}</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</section>
						</section>
					</div>
				</section>
			)}
		</>
	);
};

export const OpcionExplorar = (props) => {
	const { opcion, ilustracion, alSeleccionar, alDeseleccionar, marcarSeleccionado } = props;
	const [seleccionado, setSeleccionado] = useState(marcarSeleccionado);

	useEffect(() => {
		setSeleccionado(marcarSeleccionado);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marcarSeleccionado]);

	useEffect(() => {
		if (seleccionado) {
			alSeleccionar && alSeleccionar(opcion);
		} else {
			alDeseleccionar && alDeseleccionar(opcion);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seleccionado]);

	return (
		<button
			tabIndex={0}
			className={`
            focusable-red
            flex flex-row
            items-center
            border-solid border-2 onprimary-var1--bordered rounded-xl 
            boton-desactivado-libros cursor-pointer 
            px-2 py-1 
            flex-grow
            overflow-hidden
            boton_hover_desactivado_libros
            transicion-150
            font-bold
            place-self-center
            w-full
            h-9
            surface-var2--color
            ${seleccionado && "boton-activado-libros onprimary-inv-var2--color"} `}
			onClick={() => setSeleccionado(!seleccionado)}
		>
			{/* imagen, svg, icono o cualquier otro componente que se quiera poner como ilustración */}
			<div
				className={`${seleccionado == false && "filter grayscale"}`}
				style={{ width: 25, height: 25, minWidth: 25, minHeight: 25 }}
			>
				{ilustracion}
			</div>
			<p className="p-2 quicksand-700 text-center overflow-hidden w-50 whitespace-nowrap overflow-ellipsis">
				{opcion.nombre}
			</p>
		</button>
	);
};

Filters.propTypes = {
	/**
 * Funcion callback que devuelve un objeto con propiedades detalladas del modo filtro
 * @example 
    Function(filtros = {
        activado: false,
        filtros: {
            materias: [],
            generos: [],
            favorito: false,
            sch: '',
        },
        cantidadFiltrosActivos: 0
    })
 * @type {Function(filtros):void} - callback
 */
	alCambiarModoFiltro: PropTypes.func,
	/**
	 * evento que llama una funcion callback al momento de seleccionar el boton de limpiar flitros
	 * @type {Function():void} - callback
	 */
	alLimpiarFiltros: PropTypes.func,
};
//-----------------------------------------------------------------------------------------------
OpcionExplorar.propTypes = {
	/**
	 * Funcion callback que devuelve un objeto con id y nombre de la opcion seleccionada
	 * @type {Function(opcion={id:string, nombre:string, ... ootras propiedades}):void} - callback
	 */
	alSeleccionar: PropTypes.func,

	/**
	 * Funcion callback que devuelve un objeto con id y nombre de la opcion que se deselecciono
	 * @type {Function(opcion={id:string, nombre:string, ... ootras propiedades}):void} - callback
	 */
	alDeseleccionar: PropTypes.func,
	/**
	 * objeto con almenos un id y un nombre
	 * @example {
	 *id:'opcion-id',nombre:'opcion 1', ... otras propiedades}
	 */
	opcion: PropTypes.object.isRequired,

	/**
	 *Boolean que define si se quiere o no marcar como seleccionado, esto permite aplicar un estilo especifico para la opcion seleccionada
	 */
	marcarSeleccionado: PropTypes.bool,
};
