import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useTrackedState } from '../../store';
//Funciones
import agregarPropiedadChecked from './../../utilities/function/agregarPropiedadChecked';
import descargarTabla from '../../utilities/function/descargarTabla';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import generalCallApi from './../helpers/generalCallApi';
import getUrlParam from './../../utilities/function/getUrl';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
//Hooks persionalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import EstudiantesGeneralComponent from "../../components/estudiantes/EstudiantesGeneralComponent";
import SkeletonEstudiantesGeneral from "../../components/estudiantes/SkeletonsEstudiantes/SkeletonEstudiantesGeneral";
import { normalizeString } from '../../utilities/StringUtilities';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useTeachersQuery } from '../../hooks/api-queries/use-teachers-query';

/**
 Carga los datos de los estudiantes en el componente
EstudiantesGeneralComponent, contiene la lógica para cargar los 
cursos y sus respectivos estudiantes dependiendo el rol del usuario, 
también permite descargar los datos en formato excel y mover o eliminar uno o varios estudiantes.  

*/
const EstudiantesGeneral = (props) => {
  const { isLoadingCursos, isLoadingEstudiantes } = props;
  //Estados globales
  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  const correoUsuario = state?.datosDeUsuario?.correo;
  const usuario = state?.datosDeUsuario;
  const { datosInstitucion, datosCursos, datosEstudiantes } = state;
  const queryClient = useQueryClient();
  const teachersQuery = useTeachersQuery({enabled:rolUsuario==='profesor'});


  //Estados del componente  
  const [datosMultiSelect, setDatosMultiSelect] = useState({
    cursos: { hayResultados: false, resultados: [] },
    idCurso: null,
    idSede: null,
    nombreCurso: '',
    nombreSede: '',
  });
  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [estudiantesElegidos, setEstudiantesElegidos] = useState({
    ids: [],
    nombreSede: '',
    nombreCurso: '',
    datos: []
  })
  const [filtros, setFiltros] = useState({
    sede: { id: null, nombre: '' },
    curso: { id: null, nombre: '' }
  });
  const [instanciaDatosEstudiantes, setInstanciaDatosEstudiantes] = useState(isLoadingEstudiantes);
  const [isErrorCurso, setIsErrorCurso] = useState(false);
  const [isErrorSede, setIsErrorSede] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [primerMultiselect, setPrimerMultiselect] = useState(false);
  const [resultadosBusqueda, setResultadosBusqueda] = useState({
    hayResultados: true,
    resultados: []
  });
  const [respuestaApiMover, setRespuestaApiMover] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminar, setRespuestaApiEliminar] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedesInstituto, setSedesInstituto] = useState([]);
  const [todosLosEstudiantes, setTodosLosEstudiantes] = useState([]);


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estudiantes');


  //Otras librerias
  const history = useHistory();


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!datosCursos) {
      setDatosMultiSelect({
        ...datosMultiSelect,
        cursos: listarCursos({ idSede: null }).resultados
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos])

  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: filtros.sede.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, filtros.sede.id])


  useEffect(() => {
    if (!!datosEstudiantes && ((rolUsuario==='profesor' && teachersQuery.isSuccess && teachersQuery.data) || rolUsuario==='rector')) {
      const datosPropiedadCheck = { ...datosEstudiantes };
      const dataActualizada = inicializarChecked({ data: datosPropiedadCheck })
      resultadosEstudiantes({ data: dataActualizada });
      setInstanciaDatosEstudiantes(dataActualizada);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEstudiantes, teachersQuery.data, teachersQuery.isSuccess])



  //Borrar filtros
  const borrarFiltros = ({ refInput }) => {
    refInput.value = '';

    inicializarEstadosMultiselect();

    const filtrosLimpios = {};
    for (const key in filtros) {
      filtrosLimpios[key] = { id: null, nombre: '' };
    }

    history.push('/estudiantes');
    setIsErrorSede(false);
    setFiltros(filtrosLimpios);
    setResultadosBusqueda({
      hayResultados: todosLosEstudiantes.length !== 0,
      resultados: todosLosEstudiantes
    })
  }


  //Buscar estudiantes
  const buscarEstudiantes = ({ cursoObligatorio, idCurso, idSede, value }) => {
    const sede = idSede || filtros?.sede?.id;
    const curso = idCurso || filtros?.curso?.id;
    let coincidencias = todosLosEstudiantes?.filter(estudiante=>
      normalizeString(estudiante?.correo)?.includes(normalizeString(value)) ||
      normalizeString(estudiante?.nombre)?.includes(normalizeString(value)) 
      );
    if(sede){
      coincidencias = coincidencias?.filter(est=>est?.idSede === sede)
    }
    if(curso){
      coincidencias = coincidencias?.filter(est=>est?.idCurso === curso)
    }
  
      setResultadosBusqueda({
        hayResultados:coincidencias?.length > 0,
        resultados:coincidencias
      })
 
  }

  //Se cambia el curso por el que se desea filtrar
  const cambiarCurso = ({ idSede, idCurso, value, tipoAccion }) => {
    const sede = !!idSede ? idSede : null;
    const curso = !!idCurso ? idCurso : null;

    if (tipoAccion === 'filtros') {
      const datosSede = !!datosCursos[sede]
        ? { id: sede, nombre: datosCursos[sede].nombre }
        : { id: null, nombre: '' };

      const datosCurso = !!datosEstudiantes[sede].cursos[curso]
        ? { id: curso, nombre: datosEstudiantes[sede].cursos[curso].nombre }
        : { id: null, nombre: '' };

      setIsErrorSede(false);
      setIsErrorCurso(false);
      buscarEstudiantes({ idCurso, idSede, value })
      setFiltros({ ...filtros, sede: datosSede, curso: datosCurso });
    } else if(tipoAccion === 'multiselect') {
      setDatosMultiSelect({
        ...datosMultiSelect,
        cursos: listarCursos({ idSede: sede }).resultados,
        idCurso: curso,
        idSede: sede,
        nombreSede: instanciaDatosEstudiantes[sede].nombre,
        nombreCurso: datosEstudiantes[sede].cursos[curso].nombre
      });
    }
  }


  //Actualizar lista de profesores seleccionados
  const cambiarEstudiantesElegidos = ({ e, idSede, estudiante }) => {
    let estudiantes = [...estudiantesElegidos.datos];
    const resultados = elegirElementosCheck({ e, elemento: estudiante, datosActuales: estudiantes })

    const { ids, datos } = resultados;

    const estudiantesSeleccionados = { ids, datos };

    let multiSelect = datos.length !== 0;
    let instanceDatos = [...resultadosBusqueda.resultados];

    //Coloca todos los elementos con checked igual a false
    instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false });

    ids.forEach(id => {
      instanceDatos.forEach((instanceEstudiante, index) => {
        if (instanceEstudiante.id === id) {
          instanceDatos[index] = { ...instanceEstudiante, checked: true }
        }
      })
    })


    setPrimerMultiselect(multiSelect);
    setEstudiantesElegidos(estudiantesSeleccionados);
    setResultadosBusqueda({ ...resultadosBusqueda, resultados: instanceDatos })
  }


  //Cambia la sede por la que se desea filtrar
  const cambiarSede = ({ idSede, value, tipoAccion }) => {
    const sede = !!idSede ? idSede : null;

    if (tipoAccion === 'filtros') {
      const datosSede = !!datosCursos[sede]
        ? { id: sede, nombre: datosCursos[sede].nombre }
        : { id: null, nombre: '' };

      const datosCurso = { id: null, nombre: '' };

      setIsErrorSede(false);
      buscarEstudiantes({ cursoObligatorio: true, idSede, value })
      setFiltros({ ...filtros, sede: datosSede, curso: datosCurso });

    } else if (tipoAccion === 'ver todo') {
      const filtrosLimpios = {};
      for (const key in filtros) {
        filtrosLimpios[key] = { id: null, nombre: '' };
      }

      setIsErrorSede(false);
      setFiltros(filtrosLimpios);
      setResultadosBusqueda({
        hayResultados: todosLosEstudiantes.length !== 0,
        resultados: todosLosEstudiantes
      })

    } else if(tipoAccion === 'multiselect'){
      setDatosMultiSelect({
        ...datosMultiSelect,
        cursos: listarCursos({ idSede: sede }).resultados,
        idCurso: null,
        nombreCurso: '',
        idSede: sede,
        nombreSede: instanciaDatosEstudiantes[sede].nombre
      });
    }
  }


  //Cancelar la función del multi-action
  const cancelarAccionMultiSelect = () => {
    const instanceResultados = agregarPropiedadChecked({ data: resultadosBusqueda.resultados });

    setResultadosBusqueda({
      ...resultadosBusqueda,
      resultados: instanceResultados
    })

    inicializarEstadosMultiselect();
  }



  //Descargar reporte
  const descargarDatos = () => {
    let resultadosExcel = []
    resultadosBusqueda.resultados.map(resultado => {
      return resultadosExcel.push({
        codigo: resultado.codigo,
        nombre: resultado.nombre,
        correo: resultado.correo,
        nombreCurso: resultado.nombreCurso,
        nombreProfesor: resultado.nombreProfesor,
        nombreSede: resultado.nombreSede,
        numeroDeLibrosLeidos: resultado.numeroDeLibrosLeidos,
        numeroTotalDeSesiones: resultado.numeroTotalDeSesiones,
        tiempoPromedioDeLectura: resultado.tiempoPromedioDeLectura,
      })
    })

    descargarTabla({
      type: 'xlsx',
      nombreArchivo: `listadoEstudiantess`,
      valores: resultadosExcel,
      headerTabla: ['Código', 'Nombres y apellidos', 'Correo', 'Curso', 'Profesor', 'Sede', '# libros leidos', '# sesiones', 'Tiempo promedio']
    })
  }


  //Eliminar profesores
  const eliminarEstudiantes = async () => {
    if (!preloader) {
      setRespuestaApiEliminar({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      const pathname = '/estudiantes/borrarEstudiante';
      const properties = { idEstudiantes: estudiantesElegidos.ids };

      const resultados = await generalCallApi({ pathname, properties });
      const error = resultados.status === 0;

      console.log('borrado multiple')
      if (!error) {
        console.log('revalidar')
        queryClient.invalidateQueries({ queryKey: ["/estudiantes/obtenerListadoEstudiantes"] });
        queryClient.invalidateQueries({ queryKey: ["/sedes/obtenerInstituto"], refetchType:'none' });
        queryClient.invalidateQueries({ queryKey: ["/clubs/obtenerClubs"], refetchType:'none' });
        queryClient.invalidateQueries({ queryKey: ["/cursos/obtenerCursos"], refetchType:'none' });
      }

      inicializarEstadosMultiselect();
      setPreloader(false);

      setRespuestaApiEliminar({
        isError: error,
        mensaje: resultados.info
      })

    }
  }


  //Setea los estados que controlan las acciones del multiselect
  const inicializarEstadosMultiselect = () => {
    setDatosMultiSelect({
      cursos: listarCursos({ idSede: null }).resultados,
      idCurso: null,
      idSede: null,
      nombreCurso: '',
      nombreSede: '',
    })

    setEstudiantesElegidos({
      ids: [],
      nombreSede: '',
      nombreCurso: '',
      datos: []
    })

    setPrimerMultiselect(false);
  }


  //Inicializar la propiedad checked de los elementos
  const inicializarChecked = ({ data }) => {
    const newData = { ...data }
    for (const key in data) {
      let sede = newData[key];
      for (const keySede in sede.cursos) {
        let cursos = sede.cursos[keySede];
        const nuevoDatoEstudiante = agregarPropiedadChecked({ data: cursos.estudiantes });

        newData[key] = {
          ...newData[key], cursos: {
            ...newData[key].cursos,
            [keySede]: { ...cursos, estudiantes: nuevoDatoEstudiante }
          }
        }
      }
    }

    return newData
  }


  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }

  //Mover estudiantes de sede y curso
  const moverEstudiantes = async () => {
    if (!preloader) {
      setRespuestaApiMover({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      const pathname = '/estudiantes/moverEstudiantes';
      const properties = {
        estudiantes: estudiantesElegidos.ids,
        idCurso: datosMultiSelect.idCurso,
        idSede: datosMultiSelect.idSede,
      }

      const resultados = await generalCallApi({ pathname, properties });
      const error = resultados.status === 0;

      if (!error) {
        queryClient.invalidateQueries({ queryKey: ["/estudiantes/obtenerListadoEstudiantes"] });
        queryClient.invalidateQueries({ queryKey: ["/sedes/obtenerInstituto"], refetchType:'none' });
        queryClient.invalidateQueries({ queryKey: ["/clubs/obtenerClubs"], refetchType:'none' });
        queryClient.invalidateQueries({ queryKey: ["/cursos/obtenerCursos"], refetchType:'none' });
      }

      inicializarEstadosMultiselect();

      setPreloader(false);
      setRespuestaApiMover({
        isError: error,
        mensaje: resultados.info
      });
    };
  };


  //Listar resultados
  const resultadosEstudiantes = async ({ data }) => {
    if (!!data) {
      const paramSede = getUrlParam('sede');
      const paramCurso = getUrlParam('curso');

      const datos = listarObject({ object: data, idSede: paramSede, idCurso: paramCurso, isCursos: true, propiedad: 'estudiantes' });
      const { cursoBuscar, errorCurso, errorSede, resultados, sedeBuscar, todosLosElementos } = datos;


      const datosSede = { id: sedeBuscar, nombre: !errorSede && !!sedeBuscar ? datosEstudiantes[sedeBuscar].nombre : '' }
      const datosCurso = { id: cursoBuscar, nombre: !errorSede && !!sedeBuscar && !!cursoBuscar && !errorCurso ? datosEstudiantes[sedeBuscar].cursos[cursoBuscar].nombre : '' }


      setIsErrorSede(errorSede);
      setIsErrorCurso(errorCurso);
      setFiltros({ ...filtros, sede: datosSede, curso: datosCurso });
   
      if (rolUsuario === 'rector') {
        setTodosLosEstudiantes(todosLosElementos);
        setResultadosBusqueda(resultados);
      } else {
        let idCursosProfe = [];
        let estudiantesProfe = [];
        const teacher = teachersQuery?.getByEmail({email:correoUsuario}) || [];
          idCursosProfe = Array.isArray(teacher?.cursos) ? teacher?.cursos.map(curso=>curso?.id): [];
          estudiantesProfe = resultados?.resultados.reduce((acc, curr) => {
            const profesEstudiante = curr?.nombreProfesor.split(',').map(profe=>{
              return profe.trim();
            });
            if (idCursosProfe.includes(curr?.idCurso) && profesEstudiante.includes(usuario?.nombre?.trim())) {
              acc.push(curr);
            }
            return acc;
          }, [])
        setTodosLosEstudiantes(estudiantesProfe);
        setResultadosBusqueda({ hayResultados: true, resultados: estudiantesProfe});

      }
    }
  }


  return (
    <>
      {
        isReady && !isLoadingCursos && !isLoadingEstudiantes ?
          <EstudiantesGeneralComponent
            borrarFiltros={borrarFiltros}
            buscarEstudiantes={buscarEstudiantes}
            cambiarCurso={cambiarCurso}
            cambiarEstudiantesElegidos={cambiarEstudiantesElegidos}
            cambiarSede={cambiarSede}
            cancelarAccionMultiSelect={cancelarAccionMultiSelect}
            cursosSede={cursosSede}
            datosMultiSelect={datosMultiSelect}
            descargarDatos={descargarDatos}
            eliminarEstudiantes={eliminarEstudiantes}
            filtros={filtros}
            isErrorCurso={isErrorCurso}
            isErrorSede={isErrorSede}
            moverEstudiantes={moverEstudiantes}
            numeroEstudiantesElegidos={estudiantesElegidos.ids.length}
            primerMultiselect={primerMultiselect}
            preloader={preloader}
            resultadosBusqueda={resultadosBusqueda}
            respuestaApiEliminar={respuestaApiEliminar}
            respuestaApiMover={respuestaApiMover}
            sedesInstituto={sedesInstituto}
            textosInterfaz={{
              botones: textosInterfaz.botones,
              miga_de_pan: textosInterfaz.miga_de_pan,
              modal_eliminar: textosInterfaz.modal_eliminar,
              modal_exito: textosInterfaz.modal_exito,
              modal_mover: textosInterfaz.modal_mover,
              multi_accion: textosInterfaz.multi_accion,
              no_existe_sede: textosInterfaz.no_existe_sede,
              vista_general: textosInterfaz.vista_general,
              notificacion:textosInterfaz.notificacion,
            }}
          />
          :
          <SkeletonEstudiantesGeneral />
      }
    </>
  )
}
export default EstudiantesGeneral;

EstudiantesGeneral.propTypes = {
  /**
   * Indica si el componente esta cargando o no los cursos del instituto
   */
   isLoadingCursos: PropTypes.bool.isRequired,
   /**
    * Indicia si el componente esta cargando los datos de los estudiantes
    */
   isLoadingEstudiantes: PropTypes.bool.isRequired,
}
